import React, { Component } from "react"
import { graphql, Link } from "gatsby"
import PropTypes from "prop-types"
import AOS from 'aos'
import Layout from "../layouts"
import { Helmet } from "react-helmet"

function myFunction() {
  var winScroll = document.body.scrollTop || document.documentElement.scrollTop;
  var height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
  var scrolled = (winScroll / height) * 100;
  document.getElementById("progress-bar").style.width = scrolled + "%";
}

class ErrorTemplate extends Component {
  
  componentDidMount() {
    AOS.init();
    window.onscroll = function() {
      myFunction();
    };
    window.onload = () => {
      AOS.refresh();
    }   
  }

  render() {

    const metaData = this.props.data.allWordpressAcfOptions.group[0].edges[0].node.options.meta_data

    const allPageData = this.props.data.allWordpressPage.edges[0].node.acf

    if (typeof window === 'undefined'){
      return (
        <Layout>
          <Helmet script={[{
            type: 'application/ld+json',
            innerHTML: JSON.stringify({
              "@context": "http://schema.org",
              "@type": "WebPage",
              "url": "https://excelinstore.com/",
              "name": "Excel | Moving Retail Forward",
              "author": {
                  "@type": "Organization",
                  "name": "Excel",
                  "url": "https://excelinstore.com/",
                  "address": {
                      "@type": "PostalAddress",
                      "streetAddress": "1021 International Drive",
                      "addressLocality": "Fergus Falls",
                      "addressRegion": "MN",
                      "postalCode": "56537",
                      "addressCountry": "USA"
                  },
                  "contactPoint": {
                      "@type": "ContactPoint",
                      "contactType": "customer support",
                      "telephone": "+1-218-998-3800",
                      "email": "contact@excelinstore.com"
                  }
              },
              "description": "We guide clients through the creation of in-store visual merchandising displays and fixtures.",
              "publisher": {
                  "@type": "Organization",
                  "name": "Excel",
                  "url": "https://excelinstore.com/",
                  "address": {
                      "@type": "PostalAddress",
                      "streetAddress": "1021 International Drive",
                      "addressLocality": "Fergus Falls",
                      "addressRegion": "MN",
                      "postalCode": "56537",
                      "addressCountry": "USA"
                  }
              }
            })
          }]}>
          <html lang="en" />
          <meta charSet="utf-8" />
          <title>Excel | Error</title>
          <meta name="description" content={metaData.description} />
          <meta property="og:title" content={"Excel | Error"}  />
          <meta property="og:type" content="WebSite" />
          <meta property="og:url" content="https://excelinstore.com/" />
          <meta property="og:image" content={metaData.image.url} />
          <meta property="og:image:type" content="image/jpeg" />
          <meta property="og:description" content={metaData.description} />
          <meta property="og:site_name" content="Excel" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={"Excel | Error"} />
          <meta name="twitter:description" content={metaData.description} />
          <meta name="twitter:image" content={metaData.image.url} />
        </Helmet>
        </Layout>
      )
    }else{
      return(
        <Layout>
          <Helmet script={[{
            type: 'application/ld+json',
            innerHTML: JSON.stringify({
              "@context": "http://schema.org",
              "@type": "WebPage",
              "url": "https://excelinstore.com/",
              "name": "Excel | Moving Retail Forward",
              "author": {
                  "@type": "Organization",
                  "name": "Excel",
                  "url": "https://excelinstore.com/",
                  "address": {
                      "@type": "PostalAddress",
                      "streetAddress": "1021 International Drive",
                      "addressLocality": "Fergus Falls",
                      "addressRegion": "MN",
                      "postalCode": "56537",
                      "addressCountry": "USA"
                  },
                  "contactPoint": {
                      "@type": "ContactPoint",
                      "contactType": "customer support",
                      "telephone": "+1-218-998-3800",
                      "email": "contact@excelinstore.com"
                  }
              },
              "description": "We guide clients through the creation of in-store visual merchandising displays and fixtures.",
              "publisher": {
                  "@type": "Organization",
                  "name": "Excel",
                  "url": "https://excelinstore.com/",
                  "address": {
                      "@type": "PostalAddress",
                      "streetAddress": "1021 International Drive",
                      "addressLocality": "Fergus Falls",
                      "addressRegion": "MN",
                      "postalCode": "56537",
                      "addressCountry": "USA"
                  }
              }
            })
          }]}>
          <html lang="en" />
          <meta charSet="utf-8" />
          <title>Excel | Error</title>
          <meta name="description" content={metaData.description} />
          <meta property="og:title" content={"Excel | Error"}  />
          <meta property="og:type" content="WebSite" />
          <meta property="og:url" content="https://excelinstore.com/" />
          <meta property="og:image" content={metaData.image.url} />
          <meta property="og:image:type" content="image/jpeg" />
          <meta property="og:description" content={metaData.description} />
          <meta property="og:site_name" content="Excel" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={"Excel | Error"} />
          <meta name="twitter:description" content={metaData.description} />
          <meta name="twitter:image" content={metaData.image.url} />
        </Helmet>
        <div className="progress"><div id="progress-bar" style={{backgroundColor: '#F3A738'}}></div></div>
        <div className="hero--wrapper">
          <div className="hero blue">
            <div className="h2 longer aos-init aos-animate" data-aos="fade-up" data-aos-delay="800" dangerouslySetInnerHTML={{ __html: allPageData.title }} />
          </div>
        </div>
        <div className="error-content">
          <div className="message">
           {allPageData.body}
          </div>
          <Link to={'/'} activeClassName="active" partiallyActive={true} className="cta error">{allPageData.button_text}<span><span></span></span></Link>
        </div>
      </Layout>
      )
    }
  }
}

ErrorTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
}

export default ErrorTemplate

export const pageQuery = graphql`
  query{
    allWordpressAcfOptions {
      group(field: id) {
        edges {
          node {
            options {
              meta_data {
                description
                image {
                  url
                  alt
                }
              }
            }
          }
        }
      }
    }
    allWordpressPage(filter: {template: {eq: "404.php"}}) {
      edges {
        node {
          acf {
            body
            title
            button_text
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        subtitle
      }
    }
  }
`
